import { Navigate, useRoutes } from "react-router-dom";
import { lazy, useState } from "react";
import SuspenseFn from "../shared/suspense/Suspense.jsx";
import { PageNotFound } from "../components/not-found/Not-found.jsx";
import AuthGuard from "../core/auth-guard/Auth-guard.js";
import { Login } from "../pages/auth/login/Login.jsx";
import Register from "../pages/auth/register/Register.jsx";
// import CalendarView from "../pages/calendar/CalendarView.jsx"
import PermissonGuard from "../core/permission-guard/Permission-guard.js";
import { AccessDenied } from "../components/access-denied/Access-denied.jsx";
import SyncFloorRoom from "../pages/roomSetting/syncFloorRoom.jsx";
import ReceptionDashboard from "../pages/dashboard/ReceptionDashboard";
import { useSelector } from "react-redux";
import { selectAuthData } from "../core/store/redux/slices/Auth-slice.js";
import { USER_ROLES } from "../utils/Constants.js";
import { useMediaQuery } from "@mui/material";
const { ADMIN, RECEPTIONIST, HOUSEKEEPING_MANAGER } = USER_ROLES;

const MainLayout = lazy(() => import("../pages/layout/Layout.jsx"));
const GuestInfo = lazy(() => import("../pages/guestinfo/GuestInfo.jsx"));
const RoomSetting = lazy(() => import("../pages/roomSetting/roomSetting.jsx"));
const RoomTypeForm = lazy(() =>
  import("../pages/roomSetting/roomTypeForm.jsx")
);
const Reservation = lazy(() => import("../pages/reservation/Reservation.jsx"));
const NewReservation = lazy(() =>
  import("../pages/reservation/newReservation/NewReservation.jsx")
);
const Arrival = lazy(() => import("../pages/arrival/Arrival.jsx"));
const Departure = lazy(() => import("../pages/departure/Departure.jsx"));
// const AlertSetting = lazy(() => import("../pages/alertsetting/AlertSetting.jsx"));
const UpdateProfile = lazy(() =>
  import("../pages/updateprofile/UpdateProfile.jsx")
);
const HouseKeepingEmployees = lazy(() =>
  import("../pages/housekeeping/Employees.jsx")
);
const HouseKeepingTasks = lazy(() => import("../pages/housekeeping/Tasks.jsx"));
const SalesReports = lazy(() => import("../pages/report/SalesReport.jsx"));
const GstReports = lazy(() => import("../pages/report/GstReport.jsx"));
const HousekeepingReports = lazy(() =>
  import("../pages/report/HousekeepingReport.jsx")
);

function BaseRoutes() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [toggle, setToggle] = useState(isMobile);
  const { role_name } = useSelector(selectAuthData)?.user ?? {};
  const isHouseKeepingManager = role_name === HOUSEKEEPING_MANAGER;

  const Auth = ({ children }) => {
    const isAuthenticated = useSelector(selectAuthData).isAuthenticated;
    if (isAuthenticated) {
      return (
        <Navigate
          to={
            isHouseKeepingManager
              ? "/main/housekeeping-tasks"
              : "/main/dashboard"
          }
        />
      );
    }
    return <>{children}</>;
  };

  let routes = useRoutes([
    {
      path: "/",
      element: (
        <Navigate
          to={
            isHouseKeepingManager
              ? "/main/housekeeping-tasks"
              : "/main/dashboard"
          }
          replace
        />
      ),
    },
    {
      path: "/auth",
      children: [
        {
          path: "",
          element: <Navigate to="login" replace />,
        },
        {
          path: "login",
          element: (
            <Auth>
              <Login />
            </Auth>
          ),
        },
        {
          path: "register",
          element: <Register />,
        },
      ],
    },
    {
      path: "/main",
      element: (
        <AuthGuard>
          {SuspenseFn(
            <MainLayout
              toggle={toggle}
              setToggle={setToggle}
              isMobile={isMobile}
            />
          )}
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Navigate
              to={isHouseKeepingManager ? "housekeeping-tasks" : "dashboard"}
              replace
            />
          ),
        },
        {
          path: "dashboard",
          element: (
            <AuthGuard>
              <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                {SuspenseFn(<ReceptionDashboard />)}
              </PermissonGuard>
            </AuthGuard>
          ),
        },
        {
          path: "guestinfo/:table_type?",
          element: (
            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
              {SuspenseFn(<GuestInfo />)}
            </PermissonGuard>
          ),
        },
        {
          path: "facilitysettings",
          element: (
            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
              {SuspenseFn(<RoomSetting />)}
            </PermissonGuard>
          ),
        },
        {
          path: "facilitysettings/addroomtype",
          element: (
            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
              {SuspenseFn(<RoomTypeForm />)}
            </PermissonGuard>
          ),
        },
        {
          path: "facilitysettings/floorview",
          element: (
            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
              {SuspenseFn(<SyncFloorRoom />)}
            </PermissonGuard>
          ),
        },
        {
          path: "sales-report",
          element: (
            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
              {SuspenseFn(
                <SalesReports
                  toggle={toggle}
                  setToggle={setToggle}
                  isMobile={isMobile}
                />
              )}
            </PermissonGuard>
          ),
        },
        {
          path: "gst-report",
          element: (
            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
              {SuspenseFn(
                <GstReports
                  toggle={toggle}
                  setToggle={setToggle}
                  isMobile={isMobile}
                />
              )}
            </PermissonGuard>
          ),
        },
        // {
        //   path: "housekeeping-report",
        //   element: (
        //     <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
        //       {SuspenseFn(
        //         <HousekeepingReports
        //           toggle={toggle}
        //           setToggle={setToggle}
        //           isMobile={isMobile}
        //         />
        //       )}
        //     </PermissonGuard>
        //   ),
        // },
        {
          path: "reservations",
          element: (
            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
              {SuspenseFn(<Reservation />)}
            </PermissonGuard>
          ),
        },
        {
          path: "reservation/:entity?",
          element: (
            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
              {SuspenseFn(<NewReservation />)}
            </PermissonGuard>
          ),
        },
        {
          path: "arrival/:entity?",
          element: (
            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
              {SuspenseFn(<Arrival />)}
            </PermissonGuard>
          ),
        },
        {
          path: "departure/:entity?",
          element: (
            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
              {SuspenseFn(<Departure />)}
            </PermissonGuard>
          ),
        },
        // {
        //     path: "calendar",
        //     element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
        //         {SuspenseFn(<CalendarView />)}
        //     </PermissonGuard>,
        // },
        // {
        //     path: "alertsetting",
        //     element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
        //         {SuspenseFn(<AlertSetting />)}
        //     </PermissonGuard>,
        // },
        {
          path: "updateprofile",
          element: (
            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
              {SuspenseFn(<UpdateProfile />)}
            </PermissonGuard>
          ),
        },
        {
          path: "housekeeping",
          element: (
            <PermissonGuard
              requiredRoles={[ADMIN, RECEPTIONIST, HOUSEKEEPING_MANAGER]}
            >
              {SuspenseFn(<HouseKeepingEmployees />)}
            </PermissonGuard>
          ),
        },
        {
          path: "housekeeping-tasks",
          element: (
            <PermissonGuard
              requiredRoles={[ADMIN, RECEPTIONIST, HOUSEKEEPING_MANAGER]}
            >
              {SuspenseFn(<HouseKeepingTasks />)}
            </PermissonGuard>
          ),
        },
      ],
    },
    {
      path: "access-denied",
      element: <AccessDenied />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);

  return routes;
}

export default BaseRoutes;
