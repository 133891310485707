import { useState } from "react";
import { Label, MoreVert } from "@mui/icons-material";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Backdrop,
  Collapse,
  Box,
  MenuItem,
  Fade,
  Modal,
  tableCellClasses,
  Popover,
  Typography,
  Badge,
} from "@mui/material";
import PaymentHistory from "../paymenthistory/Payment-history";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import RecordPayment from "../common-components/record-payment/RecordPayment";
import CommonToolTip from "../common-components/ToolTip";
import { Helper } from "../../utils/Helpers";
import { useTranslation } from "react-i18next";

const { roundOff } = new Helper();

const StyledTableCell = styled(TableCell)(
  ({ theme, color, borderBottom, backgroundColor }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.orange_theme.primary.main,
      color: theme.palette.orange_theme.primary.contrastText,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 500,
      color: !!color ? color : "#707070",
      backgroundColor: backgroundColor,
      borderBottom: "none",
      [theme.breakpoints.up("md")]: {
        fontSize: "0.83vw",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "1.44vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "3.08vw",
      },
    },
  })
);

const CollapsedTableCell = styled(TableCell)(({ theme, bodyWidth }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 0,
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 500,
    width: bodyWidth,
    border: 0,
    color: "#252525",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.83vw",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.44vw",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.08vw",
    },
  },
}));

const Row = (props) => {
  const { t } = useTranslation();
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isTab = isSmUp && isMdDown;

  const { row, index } = props;
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const paymentMenus = [
    {
      label: "Record Payment",
      name: "paymentRecord",
    },
    {
      label: "payment History",
      name: "paymentHistory",
    },
  ];

  const renderActionModal = (modalType, data = null) => {
    setModalType(modalType);
    setModalOpen(true);
    setModalData(data);
  };

  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setModalOpen(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <TableRow>
        <StyledTableCell>
          <Badge
            showZero
            badgeContent={row?.booked_rooms?.length}
            overlap="circular"
            sx={{
              "& .MuiBadge-badge": {
                fontSize: 8,
                height: 14,
                width: 14,
                minWidth: 0,
                fontWeight: 500,
                color: "#FFFFFF",
                backgroundColor: "#DEA829",
              },
            }}
          >
            <Box
              sx={{
                border: "0.5px solid #DEA829",
                borderRadius: "50%",
                width: { xs: "8.33vw", sm: "4.317vw", md: "2.431vw" },
                height: { xs: "8.33vw", sm: "4.317vw", md: "2.431vw" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "2.56vw",
                    sm: "1.319vw",
                    md: "0.764vw",
                  },
                  color: "#252525",
                  fontWeight: 500,
                }}
              >
                {row?.booked_rooms?.[0]?.room_no || ""}
              </Typography>
            </Box>
          </Badge>
        </StyledTableCell>
        <StyledTableCell>
          <Box sx={{ lineHeight: "22px" }}>
            <span style={{ color: "#252525" }}>
              {row?.name.length > 15
                ? `${row?.name.slice(0, 15)}...`
                : row?.name}
            </span>
            <br />
            {row.mobile}
          </Box>
        </StyledTableCell>

        <StyledTableCell>
          <Box sx={{ lineHeight: "22px" }}>
            {t("pages.home_page.todays_activity.due")}:{" "}
            <span style={{ color: "#E90000" }}>
              ₹{roundOff(row.due_amount)}
            </span>{" "}
            <br />
            {t("pages.home_page.todays_activity.paid")}:{" "}
            <span style={{ color: "#62BA60" }}>
              ₹{roundOff(row?.current_paid_amount)}
            </span>
          </Box>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Box sx={{ display: "flex" }}>
            <IconButton onClick={handleClick} size="small">
              <MoreVert sx={{ color: "#707070" }} fontSize="small" />
            </IconButton>
          </Box>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderColor: "#FFEABA" }}
          colSpan={9}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <CollapsedTableCell sx={{ color: "#DEA829" }}>
                      {row?.mobile}
                    </CollapsedTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Popover
        id="demo-positioned-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            border: "8px",
            padding: "3% 1%",
          }}
        >
          {paymentMenus.map((menu) => (
            <MenuItem
              onClick={() => renderActionModal(menu.name, row)}
              sx={{
                fontSize: {
                  xs: "2.56vw",
                  sm: "1.44vw",
                  md: "0.83vw",
                },
                color: "#252525",
                fontWeight: 600,
              }}
            >
              {t(
                `pages.home_page.todays_activity.${menu.label
                  .toLowerCase()
                  .replace(/\s+/g, "_")}`
              )}
            </MenuItem>
          ))}
        </Box>
      </Popover>
      <Modal
        open={modalOpen}
        onClose={() => {
          if (modalType !== "paymentRecord") {
            handleCloseModal();
          }
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              borderRadius: "16px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              padding: "1.8%",
            }}
          >
            {modalType === "paymentRecord" && (
              <RecordPayment
                handleCloseModal={handleCloseModal}
                handleClosePopover={handleClosePopover}
                bookingData={modalData}
              />
            )}
            {modalType === "paymentHistory" && (
              <PaymentHistory
                bookingData={modalData}
                roomBookingID={modalData?.room_booking_id}
                getDataFromRoomBooking={true}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

const PaymentsDueActivityTable = ({ tableData = [] }) => {
  const { t } = useTranslation();
  return (
    <>
      <Table size="small" aria-label="collapsible table" stickyHeader>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, rowIndex) => (
              <Row
                key={rowIndex}
                index={(rowIndex + 1).toString().padStart(2, "0")}
                row={row}
              />
            ))
          ) : (
            <TableCell
              align="center"
              sx={{
                paddingY: { md: "18vh" },
                color: "#bab9ba",
                border: "none",
                fontSize: { xs: "3.08vw", sm: 14 },
              }}
            >
              {t("pages.home_page.todays_activity.no_data_found")}
            </TableCell>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default PaymentsDueActivityTable;
